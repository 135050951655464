import classes from './MainNavigation.module.scss'
import Link from 'next/link'
import dynamic from 'next/dynamic';
import Image from 'next/image'

const NavigationUtilities = dynamic(() => import('./NavigationUtilities'))
const PrimaryNavigation = dynamic(() => import('./PrimaryNavigation'))

const MainNavigation = ({ props }) => {
  return (
    <div className={`${classes.mainNavigation} ${classes.mainNavContainer}`}>
      <PrimaryNavigation props={props} classes={classes} />
      <div className={classes.navLogo}>
        <div className={classes.navLogoContainer}>
          <Link href="/">
            <Image
              src={props.logo.asset.url}
              fill
              alt={props.logo.asset.alt || 'Logo'}
              priority
              sizes="(max-width: 768px) 80px, 170px"
              style={{ objectFit: 'contain' }}
            />
          </Link>
        </div>
      </div>
      <NavigationUtilities props={props} classes={classes} />
    </div>
  )
}

export default MainNavigation
