import { DateTime } from 'luxon'
import { ArticleJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { getImageMetadata, formatSocialImages } from '@/utils/seoHelpers'

const ArticleSD = ({ data, footerSettings }) => {
  const router = useRouter()
  const logo = footerSettings?.logo?.asset?.url || ''

  if (!data.fields?.seo) {
    return null
  }

  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`
  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = data.fields.seo
  const content = data.fields?.content
  const author = data.fields?.sidebar?.author
  const hosts = data.fields?.sidebar?.hosts
  const images = []

  // Format date properly for schema
  const datePublished = DateTime.fromISO(
    data.fields?.publishedDate || data.createdAt
  ).toISO()
  const dateModified = DateTime.fromISO(data.updatedAt).toISO()

  // Collect all images safely
  if (shareGraphic?.asset?.url) {
    images.push(shareGraphic.asset.url)
  }

  if (data.fields?.hero?.desktopBackgroundImage?.asset?.url) {
    images.push(data.fields.hero.desktopBackgroundImage.asset.url)
  }

  if (content) {
    content.forEach((item) => {
      if (item._type === 'image' && item.asset?.url) {
        images.push(item.asset.url)
      }
    })
  }

  // Format authors with more details
  const authors = hosts
    ? hosts.hostList.map(host => ({
        "@type": "Person",
        name: host.name || '',
        url: host.url || '',
        image: host.image?.asset?.url || '',
        sameAs: host.socialLinks || []
      }))
    : author
      ? [{
          "@type": "Person",
          name: author.name || '',
          url: author.url || '',
          image: author.image?.asset?.url || '',
          sameAs: author.socialLinks || []
        }]
      : [{
          "@type": "Organization",
          name: "Sitka Salmon Shares",
          url: process.env.NEXT_PUBLIC_DOMAIN
        }];

  // Check for video content
  const video = data.fields?.hero?.youtubeVideoId ? {
    "@type": "VideoObject",
    name: metaTitle,
    description: metaDesc,
    thumbnailUrl: images[0] || '',
    contentUrl: `https://www.youtube.com/watch?v=${data.fields.hero.youtubeVideoId}`,
    embedUrl: `https://www.youtube.com/embed/${data.fields.hero.youtubeVideoId}`,
    uploadDate: datePublished
  } : undefined;

  const formattedImages = formatSocialImages(images)

  return (
    <ArticleJsonLd
      type="Article"
      url={url}
      title={metaTitle}
      images={formattedImages.map(img => img.url)}
      datePublished={datePublished}
      dateModified={dateModified}
      authorName={authors}
      description={metaDesc}
      isAccessibleForFree={true}
      publisherName="Sitka Salmon Shares"
      publisherLogo={logo || ''}
      video={video}
    />
  )
}

export default ArticleSD
