import { useEffect } from 'react'
import ContentSections from '../components/Sections/ContentSections'
import DynamicHero from '@/components/Sections/DynamicHero'
import PageSEO from '@/components/SEO/PageSEO'
import { getRecentArticlesHandles } from '@/utils/getRecentArticleHandles'
import { useCustomerContext } from '@/context/CustomerContext'
import { getSanityClient } from '../services/sanityClient'
import { getHomePage } from '@/utils/getPages'
import { PreviewButton } from '@/components/Preview/PreviewButton'
import getCollectionListProducts from '@/utils/getCollectionListProducts'
import { getFeaturedSubscriptionProducts } from '@/utils/getFeaturedSubscriptionProducts'

export default function HomePage({ page, preview, previewToken }) {
  const { customer } = useCustomerContext()

  const dynamicHeroSections = page.fields.content.filter((section) => {
    return section._type === 'dynamicHero'
  })
  // This is important because we use the order of the sections to determine when to give full bleed heros image loading priority or not
  const nonDynamicSections = page.fields.content.filter((section) => {
    return section._type !== 'dynamicHero'
  })

  let foundDynamicHero

  foundDynamicHero = dynamicHeroSections.find((section) => {
    return section.memberType === 'non subscribers'
  })

  useEffect(() => {
    if (!customer) return;

    if (customer.tags?.includes('PS')) {
      foundDynamicHero = dynamicHeroSections.find((section) => {
        return section.memberType === 'premium seafood box'
      })
    } else if (customer.tags?.includes('PSWS')) {
      foundDynamicHero = dynamicHeroSections.find((section) => {
        return section.memberType === 'premium seafood box no shellfish'
      })
    } else if (customer.tags?.includes('SF')) {
      foundDynamicHero = dynamicHeroSections.find((section) => {
        return section.memberType === 'seafood box'
      })
    } else if (customer.tags?.includes('SF-BI')) {
      foundDynamicHero = dynamicHeroSections.find((section) => {
        return section.memberType === 'bi monthly seafood box'
      })
    } else if (customer.tags?.includes('S')) {
      foundDynamicHero = dynamicHeroSections.find((section) => {
        return section.memberType === 'salmon box'
      })
    }
  }, [customer])
  

  return (
    <>
      {preview && previewToken && <PreviewButton />}
      <PageSEO seo={page.fields.seo} />
      {foundDynamicHero && <DynamicHero fields={foundDynamicHero} />}
      <ContentSections sections={nonDynamicSections} />
    </>
  )
}

// force build 1.40
export async function getStaticProps({ draftMode }) {
  const preview = draftMode || false
  const previewToken = preview ? process.env.SANITY_READ_TOKEN : ``
  if (preview && !previewToken) {
    throw new Error(
      `Preview mode is active, but SANITY_READ_TOKEN is not set in environment variables`,
    )
  }
  let page = await getHomePage(getSanityClient(previewToken))
  if (
    page?.fields?.content?.some(
      (content) => content._type === 'featuredBlogContent',
    )
  ) {
    await getRecentArticlesHandles(page.fields.content)
  }

  if (
    page?.fields?.content?.some(
      (content) => content._type === 'featuredSubscriptionBlock',
    )
  ) {
    await getFeaturedSubscriptionProducts(page)
  }

  if (
    page?.fields?.content?.some((content) => content._type === 'collectionList')
  ) {
    await getCollectionListProducts(page)
  }

  return {
    props: {
      page: page,
      previewToken,
      preview,
    },
  }
}
