import { forwardRef, useRef } from 'react'
import dynamic from 'next/dynamic'
const ProductPrimaryAnnouncement = dynamic(() => import('../ProductPrimaryAnnouncement'))
const ProductMainNavigation = dynamic(() => import('../ProductMainNavigation'))
const ProductMobileMenu = dynamic(() => import('../ProductMobileMenu'))

import { useHeaderContext } from '@/context/HeaderContext'
import SearchModal from '@/components/Layout/SearchModal'
import { useSearchModalContext } from '@/context/SearchModalContext'

import classes from './ProductHeader.module.scss'

const ProductHeader = forwardRef(({ content, pageHandle }, ref) => {
  const { hide } = useHeaderContext()
  const { searchOpen } = useSearchModalContext()
  const primaryAnnouncementRef = useRef()

  return (
    <>
      {searchOpen &&
        <SearchModal ref={primaryAnnouncementRef} />
      }
      <div ref={ref} className={`${classes['header']} ${!hide ? classes['is-visible'] : ''}`}>
        {content.primaryAnnouncement?.showAnnouncement &&
          <ProductPrimaryAnnouncement ref={primaryAnnouncementRef} props={content.primaryAnnouncement} />
        }
        <ProductMainNavigation props={content} pageHandle={pageHandle}  />
      </div>
      {pageHandle !== 'purchaseFlow' &&
        <ProductMobileMenu props={content} pageHandle={pageHandle} />
      }
    </>
  )
})

export default ProductHeader
