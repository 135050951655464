import { DateTime } from 'luxon'
import { ArticleJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const BlogSD = ({ data }) => {
  const router = useRouter()

  if (!data.fields.seo) {
    return ''
  }

  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`
  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = data.fields.seo

  // Format dates to ISO 8601
  const datePublished = data.fields?.publishedDate
    ? DateTime.fromISO(data.fields.publishedDate).toISO()
    : DateTime.fromISO(data.createdAt).toISO()

  const dateModified = DateTime.fromISO(data.updatedAt).toISO()

  // Process images
  const images = []
  if (shareGraphic?.asset?.url) {
    images.push(shareGraphic.asset.url)
  }

  // Get blog category/section if available
  const articleSection = data.fields?.category?.title ||
    data.fields?.primaryCategory?.title ||
    'Blog'

  // Get author information if available
  const author = data.fields?.author || data.fields?.sidebar?.author
  const authors = author?.name ? [{
    "@type": "Person",
    "name": author.name,
    ...(author.url && { "url": author.url }),
    ...(author.image?.asset?.url && { "image": author.image.asset.url })
  }] : undefined

  return (
    <ArticleJsonLd
      type="BlogPosting"
      url={url}
      title={metaTitle}
      images={images}
      datePublished={datePublished}
      dateModified={dateModified}
      authorName={authors}
      description={metaDesc}
      isAccessibleForFree={true}
      publisherName="Sitka Salmon Shares"
      publisherLogo="https://path-to-your-publisher-logo.jpg" // TODO: Add your publisher logo URL
      articleSection={articleSection}
    />
  )
}

export default BlogSD
