export const defaultSEO = {
  titleTemplate: '%s | Sitka Salmon Shares',
  defaultTitle: 'Sitka Salmon Shares',
  description: 'Premium wild-caught seafood delivered directly to your door from our fishermen.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: process.env.NEXT_PUBLIC_DOMAIN,
    siteName: 'Sitka Salmon Shares',
    images: [
      {
        url: process.env.NEXT_PUBLIC_DEFAULT_OG_IMAGE || 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 630"%3E%3Crect width="1200" height="630" fill="%23f0f0f0"/%3E%3C/svg%3E',
        width: 1200,
        height: 630,
        alt: 'Sitka Salmon Shares',
      },
    ],
  },
  twitter: {
    handle: '@SitkaSalmon',
    site: '@SitkaSalmon',
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
  ],
  organization: {
    name: 'Sitka Salmon Shares',
    logo: process.env.NEXT_PUBLIC_ORGANIZATION_LOGO,
  }
}