import { BreadcrumbJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const Breadcrumb = () => {
  const router = useRouter()

  let urlPathArray = router.asPath.split('/').slice(1)

  // Remove query parameters from path segments
  urlPathArray = urlPathArray.map(segment => segment.split('?')[0])

  // Define known route mappings
  const routeMappings = {
    'products': 'Products',
    'collections': 'Collections',
    'blogs': 'Blog',
    'culinary': 'Culinary',
    'stories': 'Stories',
    'recipes': 'Recipes',
    'the-catch': 'The Catch',
  }

  let itemListElements = urlPathArray.map((item, index) => {
    const path = urlPathArray
      .filter((_, itemIndex) => itemIndex <= index)
      .join('/')
    const url = `${process.env.NEXT_PUBLIC_DOMAIN}/${path}`

    // Format the name
    let name = routeMappings[item] || item
      .split('-')
      .map((s) => {
        // Handle special cases
        if (s.toLowerCase() === 'csr') return 'CSR'
        if (s.toLowerCase() === 'faq') return 'FAQ'
        if (s.toLowerCase() === 'csa') return 'CSA'
        // Handle months in The Catch URLs
        if (/^(january|february|march|april|may|june|july|august|september|october|november|december)$/i.test(s)) {
          return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
        }
        // Handle years in The Catch URLs
        if (/^\d{4}$/.test(s)) return s
        // Normal capitalization
        return s.charAt(0)?.toUpperCase() + s.slice(1)
      })
      .join(' ')

    return {
      "@type": "ListItem",
      position: index + 1,
      name,
      item: url,
    }
  })

  // Filter out empty names and update positions
  itemListElements = itemListElements
    .filter((el) => el.name !== '')
    .map((item, index) => ({
      ...item,
      position: index + 1,
    }))

  // Add home as first item if we have other items and home isn't already there
  if (itemListElements.length > 0 && itemListElements[0].name !== 'Home') {
    itemListElements.unshift({
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: process.env.NEXT_PUBLIC_DOMAIN,
    })
    // Update positions for subsequent items
    itemListElements.slice(1).forEach((item, index) => {
      item.position = index + 2
    })
  }

  return itemListElements.length > 0 ? (
    <BreadcrumbJsonLd
      itemListElements={itemListElements}
      useAppDir={false}
    />
  ) : undefined
}

export default Breadcrumb
