import Link from 'next/link'
import { useState, useEffect } from 'react'
import IconMinus from '@/svgs/minus.svg'
import Expand from 'react-expand-animated'
import { useMediaQuery } from 'react-responsive'
import { useCustomerContext } from '@/context/CustomerContext'
import { useTheCatchContext } from '@/context/TheCatchContext'
import { generateCatchUrl } from '@/utils/generateCatchUrl'

const FooterMenuItems = ({item, classes}) => {
  const customerContext = useCustomerContext()
  const theCatchContext = useTheCatchContext()
  const { customer } = customerContext
  const { monthName, year } = theCatchContext

  let theCatchUrl = generateCatchUrl(customer, monthName, year)

  const handleMediaQueryChange = (matches) => {
    if (matches) setHeight('auto')
    if (!matches) setHeight(0)
  }

  const isDesktop = useMediaQuery(
    { minWidth: 1440 }, undefined, handleMediaQueryChange
  )
  const [height, setHeight] = useState(isDesktop ? 'auto' : 0)
  const [mounted, setMounted] = useState(false)

  const toggleExpand = (e) => {
    e.preventDefault()
    if (isDesktop) {
      return false
    }
    height === 0 ? setHeight('auto') : setHeight(0)
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <li className={classes.footerNavigationListItem} key={item._key}>
      <button
        className={`${classes.footerNavigationListItemButton} ${height !== 0 ? classes.open : ''}`}
        onClick={(e) => toggleExpand(e)}
        aria-expanded={height !== 0}
        aria-controls={`footer-menu-${item._key}`}
      >
        <h2>{item.title}</h2>
        {height !== 0 && !isDesktop && (
          <IconMinus aria-hidden="true" />
        )}
      </button>
      {mounted && (
        <Expand
          open={height !== 0}
          duration={300}
          id={`footer-menu-${item._key}`}
        >
          <ul className={classes.footerMenuItems}>
            {item.navigation.menuItems.map(item => (
              <li key={item._key}>
                <Link
                  prefetch={false}
                  href={item.linkUrl ? item.linkUrl : theCatchUrl}
                  className={classes.footerMenuItem}
                >
                  <span className={classes.footerMenuItemText}>
                    {item.linkText}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </Expand>
      )}
    </li>
  );
}

export default FooterMenuItems