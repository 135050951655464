import { DateTime } from 'luxon'
import { RecipeJsonLd } from 'next-seo'
import parse from 'parse-duration'
import { useRouter } from 'next/router'

const RecipeSD = ({ data }) => {
  const router = useRouter()

  if (!data.fields.seo || !data.fields.structuredData) {
    return ''
  }

  let { activeTime, cookTime, totalTime } = { ...data.fields.hero }
  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = { ...data.fields.seo }
  const content = data.fields.content
  const { author = '' } = { ...data.fields.sidebar }
  const {
    calories = '',
    keywords = '',
    category = '',
    cuisine = '',
  } = { ...data.fields.structuredData }
  const images = []
  const ingredients = []

  // Format time durations to ISO 8601
  const formatDuration = (time) => {
    if (!time) return undefined
    if (time?.includes('½')) time = time.replaceAll('½', '1/2')
    const minutes = Math.round(parse(time, 'm'))
    return minutes ? `PT${minutes}M` : undefined
  }

  activeTime = formatDuration(activeTime)
  cookTime = formatDuration(cookTime)
  totalTime = formatDuration(totalTime)

  // Process images
  if (shareGraphic?.asset?.url) {
    images.push(shareGraphic.asset.url)
  }

  // Process content for images and ingredients
  if (content) {
    content.forEach((item) => {
      // Handle images
      if (item._type === 'image' && item.asset?.url) {
        images.push(item.asset.url)
      }

      // Handle ingredients list
      if (item._type === 'block' && item.listItem === 'bullet') {
        const ingredient = item.children
          .map(ch => ch.text)
          .join('')
          .trim()
        if (ingredient) {
          ingredients.push(ingredient)
        }
      }
    })
  }

  // Improve recipe instructions formatting
  const instructions = content.reduce((acc, item) => {
    if (item._type === 'block' && item.style === 'h2' && item.children?.[0]?.text?.toLowerCase().includes('direction')) {
      const steps = content
        .filter(step => step._type === 'block' && step.listItem === 'number')
        .map((step, index) => ({
          "@type": "HowToStep",
          name: `Step ${index + 1}`,
          text: step.children.map(child => child.text).join(''),
          url: `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}#step-${index + 1}`,
          image: step.image?.asset?.url // If you have images for steps
        }));
      acc.push(...steps);
    }
    return acc;
  }, []);

  return (
    <RecipeJsonLd
      name={metaTitle}
      description={metaDesc}
      datePublished={DateTime.fromISO(data.fields?.publishedDate || data.createdAt).toISO()}
      authorName={[author.name]}
      prepTime={formatDuration(activeTime)}
      cookTime={formatDuration(cookTime)}
      totalTime={formatDuration(totalTime)}
      keywords={keywords}
      category={category}
      cuisine={cuisine}
      calories={calories ? `${calories} calories` : undefined}
      images={images}
      recipeYield={data.fields?.structuredData?.yield || '4 servings'}
      recipeIngredient={ingredients}
      recipeInstructions={instructions}
      video={data.fields?.hero?.youtubeVideoId ? {
        name: metaTitle,
        description: metaDesc,
        thumbnailUrl: images[0],
        contentUrl: `https://www.youtube.com/watch?v=${data.fields.hero.youtubeVideoId}`,
        embedUrl: `https://www.youtube.com/embed/${data.fields.hero.youtubeVideoId}`,
        uploadDate: DateTime.fromISO(data.fields?.publishedDate || data.createdAt).toISO()
      } : undefined}
    />
  )
}

export default RecipeSD
