import dynamic from 'next/dynamic'
import { useMemo } from 'react'

const CurrentHarvest = dynamic(() => import('../../Harvest/CurrentHarvest'))
const StaticHarvest = dynamic(() => import('@/components/Harvest/StaticHarvest'))
const ProjectedHarvest = dynamic(() => import('../../Harvest/ProjectedHarvest'))
const FeaturedBlogContent = dynamic(() => import('@/components/Sections/FeaturedBlogContent'))
const FullBleedHero = dynamic(() => import('@/components/Sections/FullBleedHero'))
const SplitHero = dynamic(() => import('@/components/Sections/SplitHero'))
const FiftyFifty = dynamic(() => import('../FiftyFifty'))
const PressLogos = dynamic(() => import('@/components/Sections/PressLogos'))
const ValueProps = dynamic(() => import('@/components/Sections/ValueProps'))
const FAQs = dynamic(() => import('@/components/Sections/FAQs'))
const BlogHero = dynamic(() => import('@/components/Sections/BlogHero'))
const RecipeCategoriesList = dynamic(() => import('@/components/Sections/RecipeCategoriesList'))
const HalfHeroHalfSlider = dynamic(() => import('@/components/Sections/HalfHeroHalfSlider'))
const EmailSignupBlock = dynamic(() => import('@/components/Sections/EmailSignupBlock'))
const BrowseCategory = dynamic(() => import('@/components/Sections/BrowseCategory'))
const ArticleRow = dynamic(() => import('@/components/Sections/ArticleRow'))
const HalfVideoBlock = dynamic(() => import('@/components/Sections/HalfVideoBlock'))
const FeaturedFishCarousel = dynamic(() => import('@/components/Sections/FeaturedFishCarousel'))
const AllKnowYourFishblock = dynamic(() => import('@/components/Sections/AllKnowYourFishBlock'))
const FeaturedText = dynamic(() => import('@/components/Sections/FeaturedText'))
const FiftyFiftyImage = dynamic(() => import('@/components/Sections/FiftyFiftyImage'))
const BoldHeaderText = dynamic(() => import('@/components/Sections/BoldHeaderText'))
const ImageWithText = dynamic(() => import('@/components/Sections/ImageWithText'))
const ProductHero = dynamic(() => import('@/components/Sections/ProductHero'))
const ProductGallery = dynamic(() => import('@/components/Sections/ProductGallery'))
const ImageTextColumns = dynamic(() => import('../ImageTextColumns'))
const WYSIWYG = dynamic(() => import('@/components/Sections/WYSIWYG'))
const ContactUs = dynamic(() => import('@/components/Sections/ContactUs'))
const Accordion = dynamic(() => import('../Accordion'))
const ReviewsCarousel = dynamic(() => import('@/components/Sections/ReviewsCarousel'), {
  ssr: false
})
const FishermenPartners = dynamic(() => import('../FishermenPartners'))
const ColumnSection = dynamic(() => import('../ColumnSection'))
const BannerBlock = dynamic(() => import('../BannerBlock'))
const FeaturedProductBlock = dynamic(() => import('../FeaturedProductBlock'))
const CollectionHero = dynamic(() => import('@/components/Sections/CollectionHero'))
const CollectionList = dynamic(() => import('@/components/Sections/CollectionList'))
const FeaturedSubscriptionBlock = dynamic(() => import('../FeaturedSubscriptionBlock'))
const FullBleedVideo = dynamic(() => import('@/components/Sections/FullBleedVideo/FullBleedVideo'))
const GenericHero = dynamic(() => import('@/components/Sections/GenericHero'))
const ImageRevealSection = dynamic(
  () => import('@/components/Sections/ImageRevealSection/ImageRevealSection'),{ ssr: false }
)
const FullBleedQuestionnaire = dynamic(() => import('../FullBleedQuestionnaire'))
const MarketplaceCollections = dynamic(() => import('../MarketplaceCollections'))

const ContentSections = ({
  sections,
  harvestsLoading,
  harvests,
  disableHarvestFilters,
  product,
  variant,
  setSelectedVariant,
  productPurchaseOptions,
  isCollectionPage,
}) => {
  const collectionLists = useMemo(() => {
    if (!Array.isArray(sections)) return [];
    return sections.filter(section => section._type === 'collectionList');
  }, [sections]);

  const collectionSections = useMemo(() => {
    if (!Array.isArray(sections) || !isCollectionPage) return null;
    return collectionLists.length > 0 ? (
      <MarketplaceCollections sections={collectionLists} key="marketplace" />
    ) : null;
  }, [sections, isCollectionPage, collectionLists]);

  const filteredSections = useMemo(() => {
    if (!Array.isArray(sections)) return [];
    if (!isCollectionPage) return sections;
    return sections.filter(section => section._type !== 'collectionList');
  }, [sections, isCollectionPage]);

  if (!Array.isArray(sections)) {
    return null;
  }

  return (
    <>
      {isCollectionPage && collectionSections}
      {filteredSections.map((section, index) => {
        const type = section?._type
        const imagePriority = index < 2

        switch (type) {
          case 'hero':
            return (
              <FullBleedHero
                fields={section}
                imagePriority={imagePriority}
                key={section._key}
              />
            )
          case 'splitHero':
            return (
              <SplitHero
                fields={section}
                imagePriority={imagePriority}
                key={section._key}
              />
            )
          case 'halfContentBlock':
            return <FiftyFifty fields={section} key={section._key} />
          case 'pressLogos':
            return <PressLogos fields={section} key={section._key} />
          case 'valueProps':
            return <ValueProps fields={section} key={section._key} />
          case 'featuredBlogContent':
            return <FeaturedBlogContent fields={section} key={section._key} />
          case 'currentSellingHarvest':
            return <CurrentHarvest fields={section} key={section._key} />
          case 'currentMonthHarvest':
            return <CurrentHarvest fields={section} key={section._key} />
          case 'projectedHarvest':
            if (harvests) {
              section = {
                ...section,
                harvestList: harvests,
              }
            }
            return (
              <ProjectedHarvest
                harvestsLoading={harvestsLoading}
                fields={section}
                disableHarvestFilters={disableHarvestFilters}
                key={section._key}
              />
            )
          case 'staticHarvest':
            return <StaticHarvest fields={section} key={section._key} />
          case 'globalSampler':
            return <CurrentHarvest fields={section} key={section._key} />
          case 'faqs':
            return <FAQs fields={section} key={section._key} />
          case 'blogHero':
            return (
              <BlogHero
                fields={section}
                imagePriority={imagePriority}
                key={section._key}
              />
            )
          case 'recipeCategoriesList':
            return <RecipeCategoriesList fields={section} key={section._key} />
          case 'halfHeroHalfSlider':
            return (
              <HalfHeroHalfSlider
                fields={section}
                imagePriority={imagePriority}
                key={section._key}
              />
            )
          case 'emailSignup':
            return (
              <div className="bg-color--fawn">
                <div className="container">
                  <EmailSignupBlock
                    props={{
                      emailSignup: section,
                    }}
                    key={section._key}
                  />
                </div>
              </div>
            )
          case 'browseCategory':
            return <BrowseCategory fields={section} key={section._key} />
          case 'articleRow':
            return <ArticleRow fields={section} key={section._key} />
          case 'recipeArticleRow':
            return <ArticleRow fields={section} key={section._key} />
          case 'halfVideoBlock':
            return <HalfVideoBlock fields={section} key={section._key} />
          case 'featuredText':
            return <FeaturedText fields={section} key={section._key} />
          case 'fiftyFiftyImageBlock':
            return <FiftyFiftyImage fields={section} key={section._key} />
          case 'boldHeaderText':
            return <BoldHeaderText fields={section} key={section._key} />
          case 'imageWithText':
            return <ImageWithText fields={section} key={section._key} />
          case 'imageTextColumns':
            return <ImageTextColumns fields={section} key={section._key} />
          case 'wysiwyg':
            return <WYSIWYG fields={section} key={section._key} />
          case 'contactUs':
            return <ContactUs fields={section} key={section._key} />
          case 'accordion':
            return <Accordion fields={section} key={section._key} />
          case 'featuredFishCarousel':
            return <FeaturedFishCarousel fields={section} key={section._key} />
          case 'featuredFishermenCarousel':
            return <FeaturedFishCarousel fields={section} key={section._key} />
          case 'allKnowYourFishBlock':
            return <AllKnowYourFishblock fields={section} key={section._key} />
          case 'reviewsCarousel':
            return <ReviewsCarousel fields={section} key={section._key} />
          case 'fishermenPartners':
            return <FishermenPartners fields={section} key={section._key} />
          case 'productReviews':
            if (product) {
              return <ReviewsCarousel fields={{ products: [product] }} />
            }
            return null
          case 'columnLayout':
            return (
              <ColumnSection
                columns={section.columns}
                key={section._key}
              ></ColumnSection>
            )
          case 'bannerBlock':
            return <BannerBlock fields={section} key={section._key} />
          case 'featuredProductBlock':
            return <FeaturedProductBlock fields={section} key={section._key} />
          case 'collectionHero':
            return <CollectionHero fields={section} key={section._key} />
          case 'collectionList':
            return <CollectionList fields={section} key={section._key} />
          case 'productHero':
            return (
              <ProductHero
                fields={section}
                key={section._key}
                product={product}
                selectedVariant={variant}
                setSelectedVariant={setSelectedVariant}
                productPurchaseOptions={productPurchaseOptions}
              />
            )
          case 'productGallery':
            return (
              <ProductGallery
                fields={section}
                key={section._key}
                product={product}
              />
            )
          case 'featuredSubscriptionBlock':
            return <FeaturedSubscriptionBlock fields={section} key={section._key} />
          case 'fullBleedVideo':
            return <FullBleedVideo fields={section} key={section._key} />
          case 'imageReveal':
            return <ImageRevealSection fields={section} key={section._key} />
          case 'genericSection':
            return <GenericHero fields={section} key={section._key} />
          case 'questionnaireHero':
            return (
              <FullBleedQuestionnaire
                fields={section}
                imagePriority={imagePriority}
                key={section._key}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default ContentSections
