import { OrganizationJsonLd } from 'next-seo'

const OrganizationSD = ({ footerSettings }) => {
  const logo = footerSettings?.logo?.asset?.url || ''

  return (
    <OrganizationJsonLd
      type="Organization"
      id="sitka-salmon"
      logo={logo}
      legalName="Sitka Salmon Shares"
      name="Sitka Salmon Shares"
      address={{
        streetAddress: "216 Smith St",
        addressLocality: "Sitka",
        addressRegion: "AK",
        postalCode: "99835",
        addressCountry: "US"
      }}
      url={process.env.NEXT_PUBLIC_DOMAIN}
      sameAs={[
        "https://www.facebook.com/SitkaSeafoodMarket",
        "https://www.instagram.com/sitkaseafoodmarket",
        "https://www.youtube.com/c/SitkaSalmonShares",
        "https://www.pinterest.com/sitkaseafoodmarket"
      ]}
    />
  )
}

export default OrganizationSD