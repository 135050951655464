export const getImageMetadata = (image) => ({
  url: image?.url || image?.src || image?.asset?.url || '',
  width: 1200,
  height: 630,
  alt: image?.alt || image?.asset?.alt || ''
})

export const formatSocialImages = (images = []) => {
  if (!images || !Array.isArray(images)) return []

  return images
    .filter(image => image) // Filter out null/undefined
    .map(image => getImageMetadata(image))
    .filter(image => image.url) // Only include images with URLs
}

export const getPageType = (pathname) => {
  if (pathname.startsWith('/products/')) return 'product'
  if (pathname.startsWith('/collections/')) return 'product.group'
  if (pathname.startsWith('/blogs/culinary/recipes/')) return 'recipe'
  if (pathname.startsWith('/blogs/')) return 'article'
  return 'website'
}

export const getSocialMetadata = (page, type) => {
  const baseMetadata = {
    url: `${process.env.NEXT_PUBLIC_DOMAIN}${page.handle || ''}`,
    site_name: 'Sitka Salmon Shares'
  }

  switch(type) {
    case 'product':
      return {
        ...baseMetadata,
        type: 'product',
        title: page.content?.title,
        description: page.content?.description,
        images: page.content?.media?.map(getImageMetadata) || [],
        product: {
          price: {
            amount: page.variants?.[0]?.price,
            currency: 'USD'
          },
          availability: page.variants?.[0]?.availableForSale ? 'in stock' : 'out of stock'
        }
      }
    case 'article':
      return {
        ...baseMetadata,
        type: 'article',
        title: page.fields?.seo?.metaTitle,
        description: page.fields?.seo?.metaDesc,
        images: [getImageMetadata(page.fields?.hero?.desktopBackgroundImage)],
        article: {
          publishedTime: page.fields?.publishedDate,
          modifiedTime: page.updatedAt,
          authors: page.fields?.sidebar?.author ? [page.fields.sidebar.author.name] : [],
          tags: page.fields?.articleTags?.map(tag => tag.value) || []
        }
      }
    default:
      return {
        ...baseMetadata,
        type: 'website',
        title: page.fields?.seo?.metaTitle,
        description: page.fields?.seo?.metaDesc,
        images: page.fields?.seo?.shareGraphic ? [getImageMetadata(page.fields.seo.shareGraphic)] : []
      }
  }
}