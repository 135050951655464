import classes from './ProductFooter.module.scss'
import dynamic from 'next/dynamic';

import ProductEmailSignupBlock from '@/components/Sections/ProductEmailSignupBlock'
const FooterNavigation = dynamic(() => import('./FooterNavigation'));
const FooterBottom = dynamic(() => import('./FooterBottom'));

const ProductFooter = ({ content }) => {

  if (!content) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <div className="container">
        <ProductEmailSignupBlock props={content} />
        <FooterNavigation props={content} classes={classes} />
        <FooterBottom props={content} classes={classes} />
      </div>
    </div>
  )
}

export default ProductFooter
