import useProductsReviews from '@/hooks/useProductsReviews'
import { stripHtml } from '@/utils/htmlUtils'
import { ProductJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const ProductSD = ({ data }) => {
  const router = useRouter()
  const reviews = useProductsReviews([data?.handle])

  if (!data) return null

  const product = data.content
  const { variants } = data
  const { title, description } = product
  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`

  // Process all product images with proper dimensions
  const images = product.media.map((image) => ({
    url: image.src,
    width: image.width || 800,
    height: image.height || 800,
  }))

  // Process reviews and ratings
  const totalReviews = reviews.length
  const averageRating = totalReviews > 0
    ? reviews.reduce((total, review) => total + review.rating, 0) / totalReviews
    : undefined

  const aggregateRating = totalReviews > 0
    ? {
        "@type": "AggregateRating",
        ratingValue: averageRating.toFixed(1),
        reviewCount: totalReviews,
        bestRating: "5",
        worstRating: "1"
      }
    : undefined

  // Process SKU and other identifiers
  const gtin = variants[0]?.barcode
  const sku = variants[0]?.sku
  const mpn = variants[0]?.mpn

  // Process offers
  const offers = variants.map((variant) => ({
    "@type": "Offer",
    price: variant.price ? variant.price.toFixed(2) : '',
    priceCurrency: 'USD',
    availability: variant.availableForSale
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
    url,
    ...(variant.compareAtPrice && {
      priceValidUntil: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days from now
    }),
    ...(variant.sku && { sku: variant.sku }),
    itemCondition: "https://schema.org/NewCondition",
    seller: {
      "@type": "Organization",
      name: "Sitka Salmon Shares"
    }
  }))

  // Process reviews with structured data
  const structuredReviews = reviews?.map((review) => ({
    "@type": "Review",
    reviewRating: {
      "@type": "Rating",
      ratingValue: review.rating.toString(),
      bestRating: "5",
      worstRating: "1"
    },
    author: {
      "@type": "Person",
      name: review.author
    },
    reviewBody: review.body,
    datePublished: review.date // Assuming review has a date field
  }))

  const jsonLdProps = {
    productName: title,
    images: images.map(img => img.url),
    description: description ? stripHtml(description) : null,
    brand: {
      "@type": "Brand",
      name: "Sitka Salmon Shares"
    },
    manufacturerName: "Sitka Salmon Shares",
    offers,
    aggregateRating,
    reviews: structuredReviews
  }

  // Add optional properties if they exist
  if (gtin) jsonLdProps.gtin = gtin
  if (sku) jsonLdProps.sku = sku
  if (mpn) jsonLdProps.mpn = mpn
  if (data.productType) jsonLdProps.category = data.productType
  if (variants[0]?.weight) {
    jsonLdProps.weight = {
      "@type": "QuantitativeValue",
      value: variants[0].weight,
      unitCode: variants[0].weightUnit || "LB"
    }
  }

  return <ProductJsonLd {...jsonLdProps} />
}

export default ProductSD
