import { queryShopifyStorefront } from '@/services/shopifyStorefront'
import { captureException } from '@sentry/nextjs'

//#region GQL MUTATIONS AND QUERIES

export const CART_SCHEMA = `
    id
    createdAt
    buyerIdentity {
      email
    }
    checkoutUrl
    attributes {
      key
      value
    }
    cost {
      subtotalAmount {
        amount
      }
      totalAmount {
        amount
      }
      totalTaxAmount {
        amount
      }
      checkoutChargeAmount {
        amount
      }
    }
    discountCodes {
      applicable
      code
    }
    discountAllocations {
      discountedAmount {
        amount
      }
      ... on CartAutomaticDiscountAllocation {
        __typename
        discountedAmount {
          amount
        }
        title
      }
      ... on CartCodeDiscountAllocation {
        __typename
        discountedAmount {
          amount
        }
        code
      }
      ... on CartCustomDiscountAllocation {
        __typename
        discountedAmount {
          amount
        }
        title
      }
    }
    lines(first: 10) {
      nodes {
        id
        quantity
        discountAllocations {
          discountedAmount {
            amount
          }
          ... on CartAutomaticDiscountAllocation {
            __typename
            discountedAmount {
              amount
            }
            title
          }
          ... on CartCodeDiscountAllocation {
            __typename
            code
            discountedAmount {
              amount
            }
          }
          ... on CartCustomDiscountAllocation {
            __typename
            title
            discountedAmount {
              amount
            }
          }
        }
        cost {
          subtotalAmount {
            amount
          }
          totalAmount {
            amount
          }
          compareAtAmountPerQuantity {
            amount
          }
          amountPerQuantity {
            amount
          }
        }
        attributes {
          key
          value
        }
        merchandise {
          ... on ProductVariant {
            compareAtPrice {
              amount
            }
            id
            price {
              amount
            }
            image {
              altText
              url
            }
            availableForSale
            requiresShipping
            sku
            title
            product {
              handle
              tags
              title
              id
            }
          }
        }
        sellingPlanAllocation {
          sellingPlan {
            id
            name
            description
            options {
              name
              value
            }
            recurringDeliveries
            priceAdjustments {
              adjustmentValue {
                ... on SellingPlanPercentagePriceAdjustment {
                  adjustmentPercentage
                }
              }
            }
          }
        }
      }
    }
    deliveryGroups(first: 10) {
      nodes {
        id
        deliveryAddress {
          address1
          address2
          city
          province
          provinceCode
          zip
          formatted(withName: false, withCompany: false)
        }
        deliveryOptions {
          code
          deliveryMethodType
          estimatedCost {
            amount
          }
          handle
          title
        }
        selectedDeliveryOption {
          code
          estimatedCost {
            amount
          }
          title
        }
      }
    }
`

export const CREATE_CART_MUTATION = `mutation CreateCart($lines: [CartLineInput!], $metafields: [CartInputMetafieldInput!], $discountCodes: [String!], $buyerIdentity: CartBuyerIdentityInput, $attributes: [AttributeInput!]) {
  cartCreate(
    input: {lines: $lines, discountCodes: $discountCodes, buyerIdentity: $buyerIdentity, attributes: $attributes, metafields: $metafields}
  ) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const ASSOCIATE_CUSTOMER_MUTATION = `mutation AssociateCustomer($cartId:ID!, $customerAccessToken:String, $email:String) {
  cartBuyerIdentityUpdate(
    buyerIdentity: {customerAccessToken: $customerAccessToken, email: $email}
    cartId: $cartId
  ) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const ASSOCIATE_ADDRESS_MUTATION = `mutation AssociateAddress($cartId:ID!, $address:MailingAddressInput!, $email: String) {
  cartBuyerIdentityUpdate(
    buyerIdentity: {deliveryAddressPreferences: [{deliveryAddress: $address}], email: $email}
    cartId: $cartId
  ) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const GET_CART_QUERY = `query GetCart($cartId:ID!) {
  node(id: $cartId) {
    ... on Cart {
      ${CART_SCHEMA}
    }
  }
}`

export const ADD_LINE_ITEMS_MUTATION = `mutation AddLineItems($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const UPDATE_LINE_ITEMS_MUTATION = `mutation UpdateLineItems($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const REMOVE_LINE_ITEMS_MUTATION = `mutation RemoveLineItems($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const UPDATE_DISCOUNT_CODES_MUTATION = `mutation UpdateDiscountCodes($cartId: ID!, $discountCodes: [String!]) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const UPDATE_CUSTOM_ATTRIBUTES_MUTATION = `mutation UpdateCheckoutCustomAttributes($cartId: ID!, $attributes: [AttributeInput!]!) {
  cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

export const UPDATE_SELECTED_DELIVERY_OPTIONS = `mutation UpdateSelectedDeliveryOptions($cartId: ID!, $options: [CartSelectedDeliveryOptionInput!]!) {
  cartSelectedDeliveryOptionsUpdate(cartId: $cartId, selectedDeliveryOptions: $options) {
    cart {
      ${CART_SCHEMA}
    }
    userErrors {
      code
      field
      message
    }
  }
}`

//#endregion

//#region HELPER FUNCTIONS

export async function refreshCart(cartId) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: GET_CART_QUERY,
    variables: { cartId },
    keepalive: true,
  })
  if (result) return { cart: result?.node }
  else return result
}

export async function initCart({ newCart }) {
  const result = await queryShopifyStorefront({
    query: CREATE_CART_MUTATION,
    variables: { lineItems: [], ...newCart },
    keepalive: true,
  })
  return result?.cartCreate
}

export async function addLineItems({ cartId, lineItems }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: ADD_LINE_ITEMS_MUTATION,
    variables: { cartId, lines: lineItems },
    keepalive: true,
  })
  return result?.cartLinesAdd
}

export async function updateLineItems({
  cartId,
  lineItems: lineItemsToUpdate,
}) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: UPDATE_LINE_ITEMS_MUTATION,
    variables: {
      cartId,
      lines: lineItemsToUpdate.map((li) => ({
        attributes: li.attributes,
        id: li.id,
        quantity: li.quantity,
        merchandiseId: li.merchandise.id,
      })),
    },
    keepalive: true,
  })
  return result?.cartLinesUpdate
}

export async function deleteLineItems({ cartId, lineItems }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: REMOVE_LINE_ITEMS_MUTATION,
    variables: {
      cartId,
      lineIds: lineItems.map((li) => li.id),
    },
    keepalive: true,
  })
  return result?.cartLinesRemove
}

export async function updateDiscountCodes({ cartId, discountCodes }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: UPDATE_DISCOUNT_CODES_MUTATION,
    variables: { cartId, discountCodes },
    keepalive: true,
  })
  return result?.cartDiscountCodesUpdate
}

export async function updateOrderAttributes({ cartId, attributes = [] }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: UPDATE_CUSTOM_ATTRIBUTES_MUTATION,
    variables: { cartId, attributes },
    keepalive: true,
  })
  return result?.cartAttributesUpdate
}

export async function associateCustomerWithOrder({
  cartId,
  customerAccessToken,
  email,
}) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: ASSOCIATE_CUSTOMER_MUTATION,
    variables: { cartId, customerAccessToken, email },
    keepalive: true,
  })
  return result?.cartBuyerIdentityUpdate
}

export async function associateAddressWithOrder({ cartId, address, email }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: ASSOCIATE_ADDRESS_MUTATION,
    variables: { cartId, address, email },
  })
  return result?.cartBuyerIdentityUpdate
}

export async function selectDeliveryOptions({ cartId, options }) {
  if (!cartId) return undefined
  const result = await queryShopifyStorefront({
    query: UPDATE_SELECTED_DELIVERY_OPTIONS,
    variables: { cartId, options },
  })
  return result?.cartSelectedDeliveryOptionsUpdate
}

//#endregion

export async function postShipOptions(zip, bundledShipWeek, lineItems) {
  const body = { zip }
  if (bundledShipWeek) {
    body.bundledShipWeek = bundledShipWeek
  }
  if (lineItems) {
    body.line_items = lineItems.map((li) => ({ sku: li.variant.sku }))
  }

  const res = await fetch('/api/checkout/ship-options', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  })

  return await handleFetchResponse(res)
}

export async function handleFetchResponse(res) {
  if (res.ok) {
    return await res.json()
  } else {
    if (res.status !== 404) {
      const e = await res.json()
      console.error(`${res.status} BE api response: `, e)
      captureException(new Error(JSON.stringify(e)))
    }
    return undefined
  }
}
