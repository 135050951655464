import { VideoJsonLd } from 'next-seo';
import { DateTime } from 'luxon';

const VideoSD = ({ data }) => {
  if (!data.fields?.hero?.youtubeVideoId) {
    return null;
  }

  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = data.fields?.seo || {};

  const thumbnailUrl = shareGraphic?.asset?.url ||
    data.fields?.hero?.desktopBackgroundImage?.asset?.url ||
    `https://img.youtube.com/vi/${data.fields.hero.youtubeVideoId}/maxresdefault.jpg`;

  const uploadDate = DateTime.fromISO(
    data.fields?.publishedDate || data.createdAt
  ).toISO();

  return (
    <VideoJsonLd
      name={metaTitle}
      description={metaDesc}
      thumbnailUrls={[thumbnailUrl]}
      uploadDate={uploadDate}
      contentUrl={`https://www.youtube.com/watch?v=${data.fields.hero.youtubeVideoId}`}
      embedUrl={`https://www.youtube.com/embed/${data.fields.hero.youtubeVideoId}`}
      duration={data.fields?.hero?.videoDuration}
      expires={data.fields?.hero?.videoExpireDate}
      hasPart={data.fields?.hero?.videoChapters?.map(chapter => ({
        "@type": "Clip",
        name: chapter.name,
        startOffset: chapter.startTime,
        endOffset: chapter.endTime,
        url: `https://www.youtube.com/watch?v=${data.fields.hero.youtubeVideoId}&t=${chapter.startTime}`
      }))}
      watchCount={data.fields?.hero?.videoViews}
      publication={[{
        "@type": "BroadcastEvent",
        isLiveBroadcast: false,
        startDate: uploadDate
      }]}
      regionsAllowed="US"
    />
  );
};

export default VideoSD;