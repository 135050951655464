import { getPageType, getImageMetadata, formatSocialImages } from '@/utils/seoHelpers'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { defaultSEO } from '@/config/seo.config'

const PageSEO = ({ seo, images = [], product, collection }) => {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`
  const pageType = getPageType(router.pathname)

  // For products without SEO object, create default metadata from product data
  const productMetadata = product ? {
    metaTitle: product.content?.title,
    metaDesc: product.content?.description,
    shareTitle: product.content?.title,
    shareDesc: product.content?.description,
  } : null;

  // Use SEO object or fallback to product metadata
  const metadata = seo || productMetadata;

  // Get product price and availability
  const productPrice = product?.variants?.[0]?.price;
  const productAvailability = product?.variants?.[0]?.availableForSale
    ? 'in stock'
    : 'out of stock';

  // Handle noindex case
  if (seo?.metaNoIndex === true) {
    return <NextSeo noindex={true} nofollow={true} />
  }

  // Process images
  let allImages = [...images]

  // Handle shareGraphic structure
  if (seo?.shareGraphic?.asset?.url) {
    allImages.push({
      url: seo.shareGraphic.asset.url,
      alt: seo.shareGraphic.asset.alt || metadata?.metaTitle || ''
    })
  }

  // Handle product images
  if (product?.content?.media) {
    allImages = [...allImages, ...product.content.media.map(media => ({
      url: media.src,
      alt: media.alt || product.content.title || ''
    }))]
  }

  const formattedImages = allImages.map(image => ({
    url: image.url,
    width: 1200,
    height: 630,
    alt: image.alt || ''
  }))

  return (
    <NextSeo
      title={metadata?.metaTitle || ''}
      description={metadata?.metaDesc || ''}
      openGraph={{
        type: pageType,
        url: url,
        title: metadata?.shareTitle || metadata?.metaTitle || '',
        description: metadata?.shareDesc || metadata?.metaDesc || '',
        images: formattedImages.length > 0 ? formattedImages : undefined,
        site_name: 'Sitka Salmon Shares',
        ...(product && {
          price: productPrice,
          currency: 'USD',
          'product:price:amount': productPrice,
          'product:price:currency': 'USD',
          'product:availability': productAvailability
        })
      }}
      twitter={{
        cardType: formattedImages.length > 0 ? 'summary_large_image' : 'summary',
        site: '@SitkaSalmon',
        handle: '@SitkaSalmon'
      }}
      additionalMetaTags={[
        {
          name: 'robots',
          content: seo?.metaNoIndex ? 'noindex,nofollow' : 'index,follow'
        },
        ...(product ? [
          {
            property: 'og:price:amount',
            content: productPrice
          },
          {
            property: 'og:price:currency',
            content: 'USD'
          },
          {
            property: 'product:availability',
            content: productAvailability
          }
        ] : [])
      ]}
    />
  )
}

export default PageSEO
